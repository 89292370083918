<template>
  <div>
    <AppServicesUpdate/>
  </div>
</template>

<script>
import AppServicesUpdate from "@/components/services/AppServicesUpdate";

export default {
  name: "AppServicesCrud",
  components: {
    AppServicesUpdate,
  },
}
</script>

<style lang="scss" scoped>

</style>
