var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('ValidationObserver',{ref:"validation-observer"},[_c('div',{staticClass:"col-12 d-flex flex-wrap p-0"},[_c('b-col',{staticClass:"px-1",attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"Название [RU]","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Название [RU]","label-for":"name-ru"}},[_c('b-form-input',{attrs:{"id":"name-ru","size":"md","placeholder":"Введите"},model:{value:(_vm.name.ru),callback:function ($$v) {_vm.$set(_vm.name, "ru", $$v)},expression:"name.ru"}})],1),(errors)?_c('p',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1),_c('b-col',{staticClass:"px-1",attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"Название [UZ]","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Название [UZ]","label-for":"name-uz"}},[_c('b-form-input',{attrs:{"id":"name-uz","size":"md","placeholder":"Введите"},model:{value:(_vm.name.uz),callback:function ($$v) {_vm.$set(_vm.name, "uz", $$v)},expression:"name.uz"}})],1),(errors)?_c('p',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1),_c('b-col',{staticClass:"px-1",attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"Описание [RU]","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Описание [RU]","label-for":"description-ru"}},[_c('b-form-input',{attrs:{"id":"description-ru","size":"md","placeholder":"Введите"},model:{value:(_vm.description.ru),callback:function ($$v) {_vm.$set(_vm.description, "ru", $$v)},expression:"description.ru"}})],1),(errors)?_c('p',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1),_c('b-col',{staticClass:"px-1",attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"Описание [UZ]","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Описание [UZ]","label-for":"description-uz"}},[_c('b-form-input',{attrs:{"id":"description-uz","size":"md","placeholder":"Введите"},model:{value:(_vm.description.uz),callback:function ($$v) {_vm.$set(_vm.description, "uz", $$v)},expression:"description.uz"}})],1),(errors)?_c('p',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1),_c('b-col',{staticClass:"px-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Услуга"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Услуга","label-for":"service-id"}},[_c('b-form-select',{attrs:{"id":"service-id","placeholder":"Выберите","options":_vm.services,"value-field":"id","text-field":"name.ru"},model:{value:(_vm.serviceId),callback:function ($$v) {_vm.serviceId=$$v},expression:"serviceId"}})],1),(errors)?_c('p',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1)],1),_c('b-col',{staticClass:"px-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"image","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Изображение"}},[_c('VueFileAgent',{ref:"vueFileAgent",attrs:{"deletable":true,"meta":true,"accept":'image/*,.zip',"maxSize":'5MB',"maxFiles":1,"helpText":'Выберите изображение',"errorText":{type: 'Неверный тип файла. Разрешены только изображения или zip', size: 'Размер файла не должен превышать 10 МБ.',}},on:{"select":function($event){return _vm.filesSelected($event)},"beforedelete":function($event){return _vm.onBeforeDelete($event)},"delete":function($event){return _vm.fileDeleted($event)}},model:{value:(_vm.fileRecords),callback:function ($$v) {_vm.fileRecords=$$v},expression:"fileRecords"}})],1),(errors)?_c('p',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1)],1),_c('b-button',{staticClass:"btn-success float-right mt-2 mr-1",on:{"click":function($event){return _vm.updateService()}}},[_vm._v(" Сохранить ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }